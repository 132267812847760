import React from 'react';
import TextLoop from 'react-text-loop';
import Article from '../../components/article/Article';
import {
  algorithmecm,
  elearningalgorithmecm,
  motellareferenceca,
  jobfactorycmcom,
  detectivealgorithmecm,
  petrocertifcom,
  islapecom,
  bayamsalamcm,
  fenafoundationcom,
  starcamercom,
  arbrealgorithmecm,
  capitalalgorithmecm,
  youtubealgorithmecm,
  hostile,
  kamasutra,
  nyanga,
} from './imports';
import './blog.css';

const Blog = () => (
  <div className="gpt3__blog section__padding" id="produits">
    <div className="gpt3__blog-heading">
      <TextLoop springConfig={{ stiffness: 180, damping: 8 }}>
        <span>
          <h1 className="gradient__text">Quelques Produits</h1>
        </span>
        <span>
          <h1 className="gradient__text">Quelques Réalisations</h1>
        </span>
      </TextLoop>
    </div>
    <div className="gpt3__blog-container">
      <div className="gpt3__blog-container_groupB">
        <a href="https://ifpia.xyz" target="_blank" rel="noopener noreferrer">
          <Article
            imgUrl={algorithmecm}
            date="Cliquez pour consulter"
            text="ifpia.xyz"
          />
        </a>
        <a
          href="https://e-learning.ifpia.xyz"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Article
            imgUrl={elearningalgorithmecm}
            date="Cliquez pour consulter"
            text="e-learning.ifpia.xyz"
          />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=cm.algorithme.hostile"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Article
            imgUrl={hostile}
            date="Cliquez pour consulter"
            text="Hostile - La Guerre du Pouvoir"
          />
        </a>
        <a
          href="https://ifpia.xyz/kamasutra.apk"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Article
            imgUrl={kamasutra}
            date="Cliquez pour consulter"
            text="Kamasutra: Education sexuelle"
          />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=cm.algorithme.nyanga"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Article
            imgUrl={nyanga}
            date="Cliquez pour consulter"
            text="Nyanga: La beauté à tout prix"
          />
        </a>
        <a
          href="https://motellareference.ca"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Article
            imgUrl={motellareferenceca}
            date="Cliquez pour consulter"
            text="motellareference.ca"
          />
        </a>
        <a
          href="https://jobfactorycm.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Article
            imgUrl={jobfactorycmcom}
            date="Cliquez pour consulter"
            text="jobfactorycm.com"
          />
        </a>
        <a
          href="https://detective.algorithme.cm"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Article
            imgUrl={detectivealgorithmecm}
            date="Cliquez pour consulter"
            text="detective.algorithme.cm"
          />
        </a>
        <a
          href="https://petrocertif.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Article
            imgUrl={petrocertifcom}
            date="Cliquez pour consulter"
            text="petrocertif.com"
          />
        </a>
        <a href="https://islape.com" target="_blank" rel="noopener noreferrer">
          <Article
            imgUrl={islapecom}
            date="Cliquez pour consulter"
            text="islape.com"
          />
        </a>
        <a
          href="https://bayamsalam.cm"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Article
            imgUrl={bayamsalamcm}
            date="Cliquez pour consulter"
            text="bayamsalam.cm"
          />
        </a>
        <a
          href="https://fena-foundation.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Article
            imgUrl={fenafoundationcom}
            date="Cliquez pour consulter"
            text="fena-foundation.com"
          />
        </a>
        <a
          href="https://starcamer.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Article
            imgUrl={starcamercom}
            date="Cliquez pour consulter"
            text="starcamer.com"
          />
        </a>
        <a
          href="https://arbre.ifpia.xyz"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Article
            imgUrl={arbrealgorithmecm}
            date="Cliquez pour consulter"
            text="arbre.ifpia.xyz"
          />
        </a>
        <a
          href="https://3s-capital.algorithme.cm"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Article
            imgUrl={capitalalgorithmecm}
            date="Cliquez pour consulter"
            text="3s-capital.algorithme.cm"
          />
        </a>
        <a
          href="https://youtube.algorithme.cm"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Article
            imgUrl={youtubealgorithmecm}
            date="Cliquez pour consulter"
            text="youtube.algorithme.cm"
          />
        </a>
      </div>
    </div>
  </div>
);

export default Blog;
