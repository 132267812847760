import React from 'react';
import Typist from 'react-typist';
import TextLoop from 'react-text-loop';
import people from '../../assets/people.png';
import ai from '../../assets/ai.gif';
import './header.css';

const Header = () => (
  <div className="gpt3__header section__padding" id="accueil">
    <div className="gpt3__header-content">
      <h1 className="gradient__text">
        <Typist>
          <a
            href="https://play.google.com/store/apps/details?id=cm.algorithme.ifpia"
            target="_blank"
            rel="noopenner noreferrer"
          >
            Téléchargez notre application mobile
          </a>
        </Typist>
      </h1>
      <p>
        {/* Nous formons des{' '}
        <TextLoop springConfig={{ stiffness: 180, damping: 8 }}>
          <span className="gradient__text">Experts.</span>
          <span className="gradient__text">Professionnels.</span>
        </TextLoop>{' '} */}
        Nous créons des Solutions{' '}
        <TextLoop springConfig={{ stiffness: 180, damping: 8 }}>
          <span className="gradient__text">Grand Public.</span>
          <span className="gradient__text">pour tous.</span>
        </TextLoop>
      </p>
      <p>
        <Typist>Notre slogan: La Compétence!</Typist>
      </p>

      <div className="gpt3__header-content__people">
        <img src={people} />
        <p>
          <TextLoop springConfig={{ stiffness: 180, damping: 8 }}>
            <span>+600 000 personnes ont visité ces derniers 12 mois</span>
            <span>+50 000 personnes ont visité ces derniers 30 jours</span>
            <span>+1 000 personnes ont visité ces dernières 24 heures</span>
          </TextLoop>
        </p>
      </div>
    </div>

    <div className="gpt3__header-image">
      <img src={ai} />
    </div>
  </div>
);

export default Header;
