import React from 'react';
import TextLoop from 'react-text-loop';
import Feature from '../../components/feature/Feature';
import './whatGPT3.css';

const WhatGPT3 = () => (
  <div className="gpt3__whatgpt3 section__margin" id="services">
    <div className="gpt3__whatgpt3-feature">
      <Feature
        title="Nos Services"
        text="En tant qu'Entreprise Digitale innovante, nous offrons tous les services imaginables. De la Publicité jusqu'au Développement d'Applications en passant par les supports de communication et les services de paiement en ligne. Nous créons des Solutions Grand Public à Fort Impact à Grande Échelle pour tous. Nous utilisons les dernières technologies et pratiques grâce à une veille technologique constante."
      />
    </div>
    <div className="gpt3__whatgpt3-heading">
      <a
        href="https://projet.ifpia.xyz"
        target="_blank"
        rel="noopener noreferrer"
      >
        <h1 className="gradient__text">
          Confiez-nous votre projet{' '}
          <TextLoop springConfig={{ stiffness: 180, damping: 8 }}>
            <span className="gradient__text">maintenant</span>
            <span className="gradient__text">aujourd&apos;hui</span>
          </TextLoop>
        </h1>
      </a>
      <a
        href="https://projet.ifpia.xyz"
        target="_blank"
        rel="noopener noreferrer"
      >
        <TextLoop springConfig={{ stiffness: 180, damping: 8 }}>
          <span>
            <h2 className="gradient__text">Soumettre mon projet</h2>
          </span>
          <span>
            <h2 className="gradient__text">Confier mon projet</h2>
          </span>
        </TextLoop>
      </a>
    </div>
    <div className="gpt3__whatgpt3-container">
      <Feature
        title="Développement Web"
        text="Site Vitrine et Application Web, Site E-commerce et Site de E-learning."
      />
      <Feature
        title="Développement Mobile"
        text="Application Mobile pour Smartphone et Tablette Android, IOS et autres."
      />
      <Feature
        title="Infographie & Audio-Visuel"
        text="Spot Vidéo Professionnel, Visuel Professionnel, Logo Professionnel."
      />
    </div>
    <div className="gpt3__whatgpt3-container">
      <Feature
        title="Marketing"
        text="Marketing Viral, Marketing Digital, Community Management, Sponsoring."
      />
      <Feature
        title="Facilitation & Autres"
        text="Paiement en ligne, Lancement Produit, Autres."
      />
    </div>
  </div>
);

export default WhatGPT3;
