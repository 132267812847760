import React from 'react';
import Tada from 'react-reveal/Tada';
import {
  google,
  aws,
  facebook,
  microsoft,
  github,
  reactnative,
  reactjs,
  ionic,
  kotlin,
  xamarin,
  cordova,
  swift,
  java,
  sencha,
  angular,
  node,
  spring,
  laravel,
  symfony,
  vue,
  express,
} from './imports';
import './brand.css';

const Brand = () => (
  <div className="gpt3__brand section__padding">
    <div>
      <Tada>
        <img src={google} />
      </Tada>
    </div>
    <div>
      <Tada>
        <img src={aws} />
      </Tada>
    </div>
    <div>
      <Tada>
        <img src={facebook} />
      </Tada>
    </div>
    <div>
      <Tada>
        <img src={microsoft} />
      </Tada>
    </div>
    <div>
      <Tada>
        <img src={github} />
      </Tada>
    </div>
    <div>
      <Tada>
        <img src={reactnative} />
      </Tada>
    </div>
    <div>
      <Tada>
        <img src={ionic} />
      </Tada>
    </div>
    <div>
      <Tada>
        <img src={kotlin} />
      </Tada>
    </div>
    <div>
      <Tada>
        <img src={xamarin} />
      </Tada>
    </div>
    <div>
      <Tada>
        <img src={cordova} />
      </Tada>
    </div>
    <div>
      <Tada>
        <img src={swift} />
      </Tada>
    </div>
    <div>
      <Tada>
        <img src={java} />
      </Tada>
    </div>
    <div>
      <Tada>
        <img src={sencha} />
      </Tada>
    </div>
    <div>
      <Tada>
        <img src={reactjs} />
      </Tada>
    </div>
    <div>
      <Tada>
        <img src={angular} />
      </Tada>
    </div>
    <div>
      <Tada>
        <img src={node} />
      </Tada>
    </div>
    <div>
      <Tada>
        <img src={spring} />
      </Tada>
    </div>
    <div>
      <Tada>
        <img src={laravel} />
      </Tada>
    </div>
    <div>
      <Tada>
        <img src={symfony} />
      </Tada>
    </div>
    <div>
      <Tada>
        <img src={vue} />
      </Tada>
    </div>
    <div>
      <Tada>
        <img src={express} />
      </Tada>
    </div>
  </div>
);

export default Brand;
