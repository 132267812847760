import React, { useState } from 'react';
import TextLoop from 'react-text-loop';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from '../../assets/logo.png';
import './navbar.css';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className="gpt3__navbar">
      <div className="gpt3__navbar-links">
        <div className="gpt3__navbar-links_logo">
          <TextLoop springConfig={{ stiffness: 180, damping: 8 }}>
            <span>
              <img src={logo} />
            </span>
            <span>
              <img src={logo} />
            </span>
          </TextLoop>
        </div>
        <div className="gpt3__navbar-links_container">
          <p>
            <a href="#accueil">
              <TextLoop springConfig={{ stiffness: 180, damping: 8 }}>
                <span>Accueil</span>
                <span>Accueil</span>
              </TextLoop>
            </a>
          </p>
          <p>
            <a href="#services">
              <TextLoop springConfig={{ stiffness: 180, damping: 8 }}>
                <span>Services</span>
                <span>Services</span>
              </TextLoop>
            </a>
          </p>
          {/* <p>
            <a href="#filieres">
              <TextLoop springConfig={{ stiffness: 180, damping: 8 }}>
                <span>Filières</span>
                <span>Filières</span>
              </TextLoop>
            </a>
          </p> */}
          <p>
            <a href="#actualites">
              <TextLoop springConfig={{ stiffness: 180, damping: 8 }}>
                <span>Actualités</span>
                <span>Actualités</span>
              </TextLoop>
            </a>
          </p>
          <p>
            <a href="#produits">
              <TextLoop springConfig={{ stiffness: 180, damping: 8 }}>
                <span>Produits</span>
                <span>Produits</span>
              </TextLoop>
            </a>
          </p>
          <p>
            <a
              href="https://www.youtube.com/channel/UCbhUCsKZxGXziiHb8QtVOmg"
              target="_blank"
              rel="noopener noreferrer"
            >
              <TextLoop springConfig={{ stiffness: 180, damping: 8 }}>
                <span>YouTube</span>
                <span>YouTube</span>
              </TextLoop>
            </a>
          </p>
        </div>
      </div>
      <div className="gpt3__navbar-sign">
        {/* <a
          href="https://ifpia.xyz/brochure-ifpia.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          <p>
            <TextLoop springConfig={{ stiffness: 180, damping: 8 }}>
              <span>Télécharger la brochure</span>
              <span>Consulter la brochure</span>
            </TextLoop>
          </p>
        </a> */}
        <a
          href="https://play.google.com/store/apps/details?id=cm.algorithme.ifpia"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button type="button">Télécharger l&apos;application</button>
        </a>
      </div>
      <div className="gpt3__navbar-menu">
        {toggleMenu ? (
          <RiCloseLine
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <RiMenu3Line
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(true)}
          />
        )}
        {toggleMenu && (
          <div className="gpt3__navbar-menu_container scale-up-center">
            <div className="gpt3__navbar-menu_container-links">
              <p>
                <a href="#accueil">
                  <TextLoop springConfig={{ stiffness: 180, damping: 8 }}>
                    <span>Accueil</span>
                    <span>Accueil</span>
                  </TextLoop>
                </a>
              </p>
              <p>
                <a href="#services">
                  <TextLoop springConfig={{ stiffness: 180, damping: 8 }}>
                    <span>Services</span>
                    <span>Services</span>
                  </TextLoop>
                </a>
              </p>
              <p>
                <a href="#filieres">
                  <TextLoop springConfig={{ stiffness: 180, damping: 8 }}>
                    <span>Filières</span>
                    <span>Filières</span>
                  </TextLoop>
                </a>
              </p>
              <p>
                <a href="#actualites">
                  <TextLoop springConfig={{ stiffness: 180, damping: 8 }}>
                    <span>Actualités</span>
                    <span>Actualités</span>
                  </TextLoop>
                </a>
              </p>
              <p>
                <a href="#produits">
                  <TextLoop springConfig={{ stiffness: 180, damping: 8 }}>
                    <span>Produits</span>
                    <span>Produits</span>
                  </TextLoop>
                </a>
              </p>
              <p>
                <a
                  href="https://www.youtube.com/channel/UCbhUCsKZxGXziiHb8QtVOmg"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TextLoop springConfig={{ stiffness: 180, damping: 8 }}>
                    <span>YouTube</span>
                    <span>YouTube</span>
                  </TextLoop>
                </a>
              </p>
            </div>
            <div className="gpt3__navbar-menu_container-links-sign">
              {/* <a
                href="https://ifpia.xyz/brochure-ifpia.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p>
                  <TextLoop springConfig={{ stiffness: 180, damping: 8 }}>
                    <span>Télécharger la brochure</span>
                    <span>Consulter la brochure</span>
                  </TextLoop>
                </p>
              </a> */}
              <a
                href="https://play.google.com/store/apps/details?id=cm.algorithme.ifpia"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button type="button">Télécharger l&apos;application</button>
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
