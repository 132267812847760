import React from 'react';
import TextLoop from 'react-text-loop';
import gpt3Logo from '../../assets/logo.png';
import facebook from '../../assets/facebook-icon.png';
import youtube from '../../assets/youtube.png';
import tiktok from '../../assets/tiktok.png';
import github from '../../assets/github-icon.png';
import whatsapp from '../../assets/whatsapp.png';
import mail from '../../assets/mail.png';
import './footer.css';

const Footer = () => (
  <div className="gpt3__footer section__padding">
    <div className="gpt3__footer-heading">
      <h1 className="gradient__text">Voulez-vous entrer dans le futur ?</h1>
    </div>

    <div className="gpt3__footer-btn">
      <a
        href="https://play.google.com/store/apps/details?id=cm.algorithme.ifpia"
        target="_blank"
        rel="noopener noreferrer"
      >
        <p>
          <TextLoop springConfig={{ stiffness: 180, damping: 8 }}>
            <span>Télécharger l&apos;application</span>
            <span>Télécharger sur Play Store</span>
          </TextLoop>
        </p>
      </a>
    </div>

    <div className="gpt3__footer-links">
      <div className="gpt3__navbar-links_logo">
        <img src={gpt3Logo} alt="gpt3_logo" />
        <span className="gpt3__footer-links_logo">
          <p>Algorithme</p>
          <p>Entreprise Digitale</p>
        </span>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Plan du site</h4>
        <a href="#accueil">
          <p>Accueil</p>
        </a>
        <a href="#services">
          <p>Services</p>
        </a>
        {/* <a href="#filieres">
          <p>Filières</p>
        </a> */}
        <a href="#actualites">
          <p>Actualités</p>
        </a>
        <a href="#produits">
          <p>Produits</p>
        </a>
        <a
          href="https://www.youtube.com/channel/UCbhUCsKZxGXziiHb8QtVOmg"
          target="_blank"
          rel="noopener noreferrer"
        >
          <p>YouTube</p>
        </a>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Liens utiles</h4>
        <a
          href="https://agilemanifesto.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          <p>Manifeste</p>
        </a>
        {/* <a
          href="https://ifpia.xyz/brochure-ifpia.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          <p>Brochure</p>
        </a> */}
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Contacts</h4>
        <p>USA:</p>
        <p>Fairway Pointe Circle Orlando, Florida, USA</p>
        {/* <p>
          <a href="tel:+14045941953">
            <span>(+1) 404 594 1953</span>
          </a>
        </p> */}
        <p>AFRIQUE:</p>
        <p>Nsimeyong, Yaoundé, Cameroun</p>
        {/* <p>
          <a href="tel:+237222315614">
            <span>(+237) 222 31 56 14</span>
          </a>{' '}
          /{' '}
          <a href="tel:+237680404550">
            <span>(+237) 680 40 45 50</span>
          </a>
        </p> */}
        <a href="mailto:contact@ifpia.xyz ">
          <p>contact@ifpia.xyz</p>
        </a>
      </div>
    </div>

    <div className="gpt3__footer-copyright">
      <p>
        <span>
          <a
            href="https://www.facebook.com/people/IFPIA-%C3%89cole-Entreprise-Informatique/100091127346520/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={facebook} alt="facebook" title="Facebook" />
          </a>
        </span>{' '}
        <span>
          <a
            href="https://www.youtube.com/channel/UCbhUCsKZxGXziiHb8QtVOmg"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={youtube} alt="YouTube" title="YouTube" />
          </a>
        </span>{' '}
        <span>
          <a
            href="https://www.tiktok.com/@algorithme.cm"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={tiktok} alt="TikTok" title="TikTok" />
          </a>
        </span>{' '}
        <span>
          <a
            href="https://github.com/AlgorithmeCM"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={github} alt="GitHub" title="GitHub" />
          </a>
        </span>{' '}
        <span>
          <a
            href="https://wa.me/237680404550"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={whatsapp} alt="WhatsApp" title="WhatsApp" />
          </a>
        </span>{' '}
        <span>
          <a
            href="mailto:contact@ifpia.xyz"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={mail} alt="Mail" title="Mail" />
          </a>
        </span>
      </p>
      <br />
      <a href="https://ifpia.xyz" target="_blank" rel="noopener noreferrer">
        <p>
          Copyright © algorithme 2010 - {new Date().getFullYear()}, All rights
          reserved.
        </p>
      </a>
    </div>
  </div>
);

export default Footer;
