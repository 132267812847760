import React from 'react';
import TextLoop from 'react-text-loop';
import './cta.css';

const CTA = () => (
  <div className="gpt3__cta">
    <div className="gpt3__cta-content">
      <TextLoop springConfig={{ stiffness: 180, damping: 8 }}>
        <span>
          <p>10% de remise sur tous nos services</p>
        </span>
        <span>
          <p>Offre valable jusq&apos;au 09 Janvier 2023</p>
        </span>
      </TextLoop>
      <h3>Démarquez-vous de vos concurrents maintenant!</h3>
    </div>
    <div className="gpt3__cta-btn">
      <a
        href="https://projet.ifpia.xyz"
        target="_blank"
        rel="noopener noreferrer"
      >
        <TextLoop springConfig={{ stiffness: 180, damping: 8 }}>
          <span>
            <button type="button">Soumettre mon projet</button>
          </span>
          <span>
            <button type="button">Confier mon projet</button>
          </span>
        </TextLoop>
      </a>
    </div>
  </div>
);

export default CTA;
