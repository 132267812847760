import React from 'react';
import TextLoop from 'react-text-loop';
import possibilityImage from '../../assets/actualite.gif';
import './possibility.css';

const Possibility = () => (
  <div className="gpt3__possibility section__padding" id="actualites">
    <div className="gpt3__possibility-image">
      <img src={possibilityImage} alt="possibility" />
    </div>
    <div className="gpt3__possibility-content">
      <TextLoop springConfig={{ stiffness: 180, damping: 8 }}>
        <span>
          <h1 className="gradient__text">Actualités</h1>
        </span>
        <span>
          <h1 className="gradient__text">News</h1>
        </span>
      </TextLoop>
      <p>
        Nous travaillons actuellement avec nos étudiants sur une application
        mobile révolutionnaire pour le monde entier. Vous y retrouverez tous les
        plaisirs de votre quotidien. C&apos;est vague, nous le savons. Mais
        croyez-nous, cela vaut la peine d&apos;attendre. La premiere version
        sera disponible au courant de l&apos;année 2023.
      </p>
      {/* <p>
        <TextLoop springConfig={{ stiffness: 180, damping: 8 }}>
          <span className="gradient__text">Formations Accélérées:</span>
          <span className="gradient__text">Formations Diplômantes:</span>
        </TextLoop>
        <ul>
          <li>Faire de la publicité sur Facebook</li>
          <li>Faire de la publicité sur Instagram</li>
          <li>Faire de la publicité sur Youtube</li>
          <li>Faire de la publicité sur Google</li>
          <li>Faire un montage photo pro avec son Smartphone</li>
          <li>Faire un tournage vidéo pro avec son Smartphone</li>
          <li>Faire un montage vidéo pro avec son Smartphone</li>
          <li>
            Taper sur un clavier d&apos;ordinateur comme un pro (dactylographie)
          </li>
          <span className="gradient__text">
            Durée de la formation: 4 semaines
            <br />
            Inscription: 2 500 F<br />
            Coût: 10 000 F<br />
            Samedi: 08H-12H 13H-17H
            <br />
            Dimanche: 08H-12H 13H-17H
            <br />
            Formation en présentiel et en ligne
            <br />
            Accès sous réservation
          </span>
        </ul>
      </p> */}
      <p>
        Si vous pensez être une âme spéciale avec une motivation forte de
        concevoir des produits adaptés à ce monde en pleine mutation,
        n&apos;hésitez pas à nous soumettre votre candidature.
      </p>
      <a
        href="https://projet.ifpia.xyz"
        target="_blank"
        rel="noopener noreferrer"
      >
        <TextLoop springConfig={{ stiffness: 180, damping: 8 }}>
          <span>
            <h4>Soumettre mon projet</h4>
          </span>
          <span>
            <h4>Confier mon projet</h4>
          </span>
        </TextLoop>
      </a>
    </div>
  </div>
);

export default Possibility;
